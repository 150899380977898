import React from 'react';
import { mdiFilePdf } from '@mdi/js';
import { List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Header from '../../components/header';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { useStyles } from '../../utils/transparencia-difusion/styles';
import { useGlobalStyles } from '../../utils/styles';
import { cuentasBancarias, cuentasFuente, cuentasUpdatedAt } from '../../utils/servicios-prestaciones/constants';
import Icon from '@mdi/react';

const CuentasBancarias = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title='Cuentas bancarias' />

      <div className={globalClasses.container}>
        <Header title='Formatos para trámite de apertura de cuenta de menores y pensionados que radican en otra entidad' />

        <div className={globalClasses.content}>
          <Typography>Imprimir el formato y acudir a cualquier sucursal HSBC junto con la documentación mencionada.</Typography>

          <List className={classes.list}>
            {cuentasBancarias.map((cuenta, index) => (
              <a key={index} href={cuenta.to} target="_blank" rel="noopener noreferrer">
                <ListItem button>
                  <ListItemAvatar>
                    <Icon path={mdiFilePdf} size={1.7} color='#e54e5d' />
                  </ListItemAvatar>
                  <ListItemText primary={cuenta.name} secondary={cuenta.size} />
                </ListItem>
              </a>
            ))}
          </List>
        </div>

        <UpdatedInfo fuente={cuentasFuente} updatedAt={cuentasUpdatedAt} />
      </div>
    </Layout>
  );
};

export default CuentasBancarias;