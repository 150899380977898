import { getApiUrl } from "../functions";

export const fuente = "Direcciones de Prestaciones Socioeconómicas";
export const updatedAt = "24 de enero de 2019";

// CUENTAS BANCARIAS

export const cuentasFuente = 'Dirección de Prestaciones Socioeconómicas';
export const cuentasUpdatedAt = '24 de enero de 2019';

export const cuentasBancarias: { name: string, to: string, size: string }[] = [
  {
    name: 'Carta de apertura de cuenta para pensionados que radican en otra entidad',
    to: `${getApiUrl('portal')}/static/docs/servicios-prestaciones/cuentas-bancarias/apertura-pensionados-otra-entidad.pdf`,
    size: '186 KB'
  },
  {
    name: 'Carta de apertura de cuenta de menores de edad',
    to: `${getApiUrl('portal')}/static/docs/servicios-prestaciones/cuentas-bancarias/apertura-menores-edad.pdf`,
    size: '193 KB'
  }
];